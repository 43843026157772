/**
 * Functionality for sticky contact tab.
 *
 * @module feature/StickyContact
 */
import 'bootstrap/js/dist/collapse.js';
import lodash from 'lodash';

/**
 * Shows/hides sticky contact tab on scroll
 *
 * @requires bootstrap/collapse
 * @requires lodash
 * @memberof module:feature/StickyContact
 * @version 1.0.0
 * @author Peter van Gennip <peter.van.gennip@valtech.nl>
 */
class StickyContact {
    /**
     * Upgrades DOM element and initializes collapsibles and triggers.
     * @param {DOMElement} element DOM element to upgrade.
     */
    constructor(element) {
        this.$element = $(element);
        this.$trigger = this.$element.find('.js-collapse-trigger');
        this.collapsible = this.$element.find('.collapse');
        this.allowStateChange = true;
    }

    /**
     * Add collapse trigger click events and handle scroll and resize events.
     */
    init() {
        // trigger collapse/expand event
        this.$trigger.on('click', this.toggleCollapse.bind(this));

        $(window).on(
            'scroll',
            lodash.debounce(() => {
                this.scrollEvent();
            }, 200)
        );

        $(window).on(
            'resize',
            lodash.debounce(() => {
                this.scrollEvent();
            }, 200)
        );
    }

    /**
     * On scroll expand or collapse element
     */
    scrollEvent() {
        if (viewport.is('>sm')) {
            let windowTop = $(window).scrollTop();
            let windowHeight = $(window).height();
            let documentHeight = $(document).height();

            if (windowTop + windowHeight === documentHeight && this.allowStateChange === true) {
                this.$element.addClass('is-open');
                this.$trigger.addClass('is-open');
                this.collapsible.collapse('show');
            } else if (windowTop + windowHeight < documentHeight && this.allowStateChange === true) {
                this.$element.removeClass('is-open');
                this.$trigger.removeClass('is-open');
                this.collapsible.collapse('hide');
            }
        }
    }

    /**
     * Collapse/expand toggler
     */
    toggleCollapse(e) {
        e.preventDefault();

        // on click to toggle expand/collapse state make sure
        // expand / collapse on scroll does not happen anymore
        this.allowStateChange = false;

        if (!this.$element.hasClass('is-open')) {
            this.$element.addClass('is-open');
            this.$trigger.addClass('is-open');
            this.collapsible.collapse('show');
        } else {
            this.$element.removeClass('is-open');
            this.$trigger.removeClass('is-open');
            this.collapsible.collapse('hide');
        }
    }
}

// register to Component Handler
window.ComponentHandler.register({
    constructor: StickyContact,
    classAsString: 'StickyContact',
    cssClass: 'js-sticky-contact',
});

export default StickyContact;
